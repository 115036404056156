module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#005795',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: '962z9y1B9Fq2wfSKPWmHMv5CSjI883Pya9j8Dt8oCP2WN6iK',
  // apiKey: '1K3I97MUjADoctE0g6x543UD8NBuN5cGEgJNV7KSI2kzKwq7', // Ted
};
